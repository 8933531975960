import React from "react";
import ReactPlayer, { getCurrentTime } from "react-player/youtube";
import { UserContext } from "./User";
import { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useParams, useNavigate, useLocation } from "react-router";
import useScore from "../hooks/useScore";
import useProgress from "../hooks/useProgress";
import useAnalytics from "../hooks/useAnalytics";
import useTexts from "../hooks/useTexts";

import HomeButton from "./HomeButton";
import Footer from "./Footer";
import TaskCard from "./TaskCard";
import ArrowLeft from "./ArrowLeft";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import { addDoc, updateDoc, doc, collection } from "firebase/firestore";
import db from "../firebase";
import { DataContext } from "./DataContext";

export default function Video() {

	const {DATA, setData} = useContext(DataContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { url } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/,'')-1);
	const { points } = location.state;

	const { data } = location.state;
	const { user, setUser } = useContext(UserContext);
	const name = user.firstName;
	const [played, setPlayed] = useState(0);
	const [taskHidden, setTaskHidden] = useState(false);
	const [duration, setDuration] = useState(0);
	const [scoreIncrement, setScoreIncrement] = useState(points);
	const action = "video";
	const id = Cookies.get().id;
	const navigate = useNavigate();
	const [package_points, setPackage_points] = useState(0);
	const [userScore, setUserScore] = useState(0);
	const optional = {
		times_viewed: 5,
		time_watched: played,
	};
	const [updateScore] = useScore(scoreIncrement, id);
	const [updateProgress] = useProgress(user.progress, id);
	const [addAnalytics] = useAnalytics('Watched video', id, data, user.project_id);

	const [fire, setFire] = useState(false);

	const videoStyle = {
		display: "flex",
		width: "90%",
		margin: "auto",
	};


	//console.log(data);
	//console.log(url);

	useEffect(() => {
		if (user.progress && user.project_id && user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
			setUserScore(user.score);
		}
	}, [user, user.progress, user.project_id]);


	//=======================================
	var texts = {
	    email: {
		subject: 'Module two of Lagoon awaits you, #username#!',
		html: '\
		    <p>Hi, #username#!</p><p>Congrats on completing Module 1 and unlocking three new activities!</p>				<p>&nbsp;</p>				<p>Explore your new activities, including augmented reality, by clicking this link on your phone #link# or scanning the QR code below on your mobile device.&nbsp;</p>				<p>&nbsp;</p><img width="250px" height="250px" style="margin-top: 20px; margin-bottom: 10px; max-width: 250px; width: 250px; height: 250px;" src="https://firebasestorage.googleapis.com/v0/b/lagoon-118b4.appspot.com/o/lagoon_live-qr2.png?alt=media&token=3c9fca39-2f2b-42f9-9f35-0791a5e95e64">				<p>&nbsp;</p>				<hr>				James Rice<br>				503-734-6474<br>				<a href="mailto:james@lagoonlive.com">james@lagoonlive.com</a>',
	    },
	    sms: {
		text: 'You have now unlocked additional activities in Lagoon. More points await! Explore your new activities, including augmented reality, by clicking this link #link#',
	    }
	};
	var templates = [
	    {
		code: '#username#',
		value: user.firstName,
	    },
	    {
		code: '#link#',
		value: 'https://app.lagoon.live/#/?unlock=9090909d9kz',
	    }
	];
	texts = useTexts('unlocked', templates, texts);
	//console.log(texts);
	//=======================================


	const update = async () => {
		const user_ref = await doc(db, "users", Cookies.get().id);

		if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']){

			let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];

			if (reward.name != ''){
				user.rewards.push({
					name: reward.name,
					price: reward.price,
					image: reward.image,
					redeemed: false,
					rewards: user.rewards,
					instruction: reward?.instruction,
				});
			}
		}

		user.setprogress = false;
		setUser(Object.assign({}, user));

		user.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
		user.progress[user.project_id]['packets'][packet_index].package_points = parseInt(user.progress[user.project_id]['packets'][packet_index].package_points) + parseInt(points);
		console.log('points set! ', user.progress[user.project_id]['packets'][packet_index].package_points)

		await updateDoc(user_ref, {
			// progress: user.progress,
			// score: userScore + points,
		});
		// await addDoc(collection(db, "mail"), {
		// 	to: user.email,
		// 	message: {
		// 		subject: texts['email']['subject'],
		// 		html: texts['email']['html'],
		// 	},
		// });
		// if (user.phone) {
		// 	await addDoc(collection(db, "sms"), {
		// 		to: user.phone,
		// 		body: texts['sms']['text'],
		// 	});
		// }

		// Cookies.set("showProgressNotificationModuleOne", true);
		// Cookies.set("didShowProgressNotificationModuleOne", false);
		// navigate("/", { replace: true });
		// setPopUpMessage("You just unlocked Module One!");
		// setPopUp(true);
		// setFire(true);
	};

	const handleClick = async () => {
		await updateScore();
		await update();
		await updateProgress();
		await addAnalytics();
		setFire(true);
	};


	useEffect(() => {
		if (fire) {
			setTimeout(() => {
				// alert('navigate')
				navigate("/");
			}, 200);
		}
	}, [fire]);

	const videoFinished = async () => {
		console.log("done");
		// updateScore();
		await addAnalytics();
		update();
	};

	const buttonStyle = {
		display: "flex",
		margin: "10px auto",
		textAlign: "center",
		color: "blue",
		border: "1px solid purple",
	};
	const taskDescription = name + ", " + data.taskDescription;

	return (
		<div className="video">
			<header>
				<div className="top">
					<ArrowLeft />
					Watch Video
				</div>
			</header>
			{/* <ConfettiComp fire={fire} />
			<PopUpMessage trigger={popUp}>
				{
					<div>
						<h2>Congratulations!</h2>
						<p>{popUpMessage}</p>
					</div>
				}
			</PopUpMessage> */}
			<div className={taskHidden ? "task-hidden" : "yo"}>
				<TaskCard taskDescription={taskDescription} />
			</div>
			<div className="video-and-skip-container">
				<div className="video-wrapper">
					<ReactPlayer
						controls
						config={{
							youtube: {
								playerVars: {
									autoplay: true,
									showinfo: 0,
									modestbranding: 1,
									controls: 1,
								},
							},
							vimeo: {
								playerOptions: {
									autoplay: false,
									background: false,
								},
							},
						}}
						//light="https://firebasestorage.googleapis.com/v0/b/lagoon-118b4.appspot.com/o/video-thumb.png?alt=media&token=dbdc7bf8-8a8d-44c6-8e02-3edfd4ea9542"
						width="100%"
						height="100%"
						onStart={() => {
							setTaskHidden(true);
						}}
						onPause={() => {
							setTaskHidden(false);
						}}
						// onProgress={(time) => {
						// 	setPlayed(time.playedSeconds);
						// 	setScoreIncrement(5 * (played / duration).toFixed());
						// 	// setScoreIncrement(5);
						// }}
						onEnded={() => videoFinished()}
						onDuration={(time) => {
							setDuration(time);
						}}
						// style={videoStyle}
						url={data.url}
					/>
				</div>
				<button className="skip-btn" onClick={handleClick}>
					Add to wallet
				</button>
			</div>
			{/* <HomeButton /> */}

			<Footer />
		</div>
	);
}
