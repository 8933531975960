import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
    useSearchParams,
    useParams,

} from "react-router-dom";
import "./styles/App.scss";
import React, { useContext, useState, useEffect, useMemo } from "react";
import Cookies from "js-cookie";
import { updateDoc, doc, onSnapshot, query, collection, where, getDocs } from "firebase/firestore";

import { UserContext } from "./components/User";
import { DataContext } from "./components/DataContext";

import db from "./firebase";
import Video from "./components/Video";
import Downloader from "./components/Downloader";
import Intro from "./components/Intro";
import Board from "./components/Board";
import ProfilePage from "./components/ProfilePage";
import Dashboard from "./components/Dashboard";
import DashboardProject from "./components/DashboardProject";
import MemoryGame from "./components/MemoryGame";
import MemoryGame2 from "./components/MemoryGame2";
// import FoundMatch from "./components/FoundMatch"
import ProtectedRoute from "./components/ProtectedRoute";
import FullDashboard from "./components/FullDashboard";
import SignUp from "./components/SignUp";
import Quiz from "./components/Quiz";
import Qr from "./components/Qr";
import adapter from "webrtc-adapter";
import Activities from "./components/Activities";
import Packets from "./components/Packets";
import Rewards from "./components/Rewards";
import Avatar from "./components/Avatar";
import { ReactComponent as LagoonLogoLoader } from "./assets/logo.svg";
import PhotoBooth from "./components/PhotoBooth";
import Admin from "./components/Admin";
import Bio from "./components/Bio";
import Twilio from "./components/Twilio";
import SendMessage from "./components/sendMessage";
import SignUp1 from "./components/SignUp1";
import { DefaultData, ProcessData } from "./components/Data";


function App() {

    const [user, setUser] = useState({});
    const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);

    const [DATA, setDATA] = useState(DefaultData());
    const providerValueDATA = useMemo(() => ({ DATA, setDATA }), [DATA, setDATA]);


    // const providerValue1 = useMemo(() => ({ fire, setFire }), [fire, setFire]);
    const [bgColor, setBgColor] = useState("$dark-purple");

    const [projectId, setProjectId] = useState(false);

    const userId = Cookies.get().id;

    const [Logo, setLogo] = useState("");

    useEffect(() => {

        if (typeof (DATA) !== 'undefined' && typeof (DATA['logo']) !== 'undefined' && DATA['logo'] != '')
            setLogo(DATA['logo']);
    }, [DATA]);

    //===============================

    //*** update project id

    const updateProjectId = async (code) => {

        console.log(code);
        console.log(Cookies.get().id);

        if (code && Cookies.get().id) {
            const user_ref = await doc(db, "users", Cookies.get().id);
            await updateDoc(user_ref, {
                project_id: code,
            });
        }
    }

    //===============================

    //*** get project by code ***

    const getProject = async (code) => {


        //console.log('check code');
        //console.log(DATA);

        if (code != 'undefined') {

            //console.log(DATA);
            //console.log(user);

            if (((!DATA['existingProject'] || DATA['auth']) && !DATA['search_project']) && user.firstName && !DATA['newProject']) {

                console.log(DATA);

                //console.log('get project by code '+code);

                if (DATA['auth']) {
                    let temp = Object.assign([], DATA);
                    temp['search_project'] = true;
                    setDATA(temp);
                }

                const project_ref = await doc(db, "projects", code);

                onSnapshot(project_ref, (doc) => {

                    let project = doc.data();

                    console.log(project);

                    //====================
                    // add user to project

                    /*
                    if (userId) {

                        let project_users = [];

                        let found = false;

                        if (project['users']){

                            for (let project_user in project['users']) {

                                if (project_user == userId) {

                                    found = true;

                                }
                            }
                        }

                        if (!found){
                            project_users.push(userId);
                            project['users'] = project_users;
                            updateDoc(project_ref, {
                                users: project_users,
                            });
                        }
                    }
                    */

                    //=====================

                    if (!!project) {

                        console.log('got project id = ' + code);

                        setProjectId(code);
                        if (!Cookies.get('projectId') || Cookies.get('projectId') != code)
                            Cookies.set('projectId', code, { path: '/' });

                        let test = user;
                        test.project_id = code;
                        setUser(test);

                        updateProjectId(code);

                        //console.log('@@@');
                        //console.log(code);
                        //console.log(test);
                        //console.log(DATA.ID);

                        //if (DATA['defaultData']) {

                        let Data = [];
                        Data['PACKETS'] = project['PACKETS'];
                        if (!!project['COLORS'])
                            Data['COLORS'] = project['COLORS'];
                        Data['ID'] = code;
                        Data['projectId'] = code;
                        Data['logo'] = project['logo'];
                        Data['status'] = project['status'];
                        Data['texts'] = project['texts'];
                        Data['ui_texts'] = project['ui_texts'];
                        Data['avatars'] = project['avatars'];
                        Data['inAppNotifications'] = project['inAppNotifications'];
                        Data['rewards'] = project['rewards'];
                        Data['admin'] = project['user'];

                        Data['existingProject'] = true;

                        console.log('load DATA');
                        //console.log(Data);

                        Data = ProcessData(Data);


                        console.log(Data);

                        setDATA(Data);


                        //}

                        //unlock


                        //const foo = params.get('foo'); // bar
                    }
                });
            }
        }
    };

    //===============================

    //*** get project by cookies ***

    const cookiesProjectId = Cookies.get().projectId;

    useEffect(() => {

        if (cookiesProjectId) {

            getProject(cookiesProjectId);
        }

    }, [user, cookiesProjectId]);

    //===============================

    //*** set progress and rewards ***

    useEffect(() => {

        user.setprogress = false;
        setUser(Object.assign({}, user));

        //console.log(DATA);
        //console.log(user);

        if (!DATA['defaultData'] && DATA['PROGRESS'] && user && !DATA['auth']) {

            console.log('set progress and rewards (change user, DATA)');
            //console.log(user);
            //console.log(DATA);

            if (!user.progress || !user.progress[DATA.ID]) {

                if (!user.progress) {
                    user['progress'] = {};
                }

                if (!user.progress[DATA.ID]) {

                    user.progress[DATA.ID] = DATA['PROGRESS'];

                    user.progress = getBio(user['progress'], user['project_id']);

                    console.log('set progress');
                    user.setprogress = true;

                    setUser(Object.assign({}, user));
                    updateUserProgress(user['progress']);

                    console.log('set user progress');

                }

            } else {

                // synchronize changes

                //if (!user.setprogress)
                {

                    let is_changed = false;
                    for (let key in DATA['PROGRESS']['packets']) {

                        if (!user.progress[DATA.ID]['packets'][key]) {
                            user.progress[DATA.ID]['packets'][key] = DATA['PROGRESS']['packets'][key];

                            is_changed = true;
                        }

                        for (let key2 in DATA['PROGRESS']['packets'][key]['activities']) {
                            if (!user.progress[DATA.ID]['packets'][key]['activities'][key2]) {
                                user.progress[DATA.ID]['packets'][key]['activities'][key2] = DATA['PROGRESS']['packets'][key]['activities'][key2];
                                is_changed = true;
                            }
                        }
                    }

                    if (is_changed) {

                        user.progress = getBio(user['progress'], user['project_id']);
                        updateUserProgress(user['progress']);
                        console.log('set user progress changes');
                    } else {

                        console.log('user progress is up to date');
                    }

                    console.log('set progress (update)');
                    user.setprogress = true;
                    setUser(Object.assign({}, user));


                    // NOW CAN HIDE THE LOADER
                    document.querySelector('.loader').classList.add('loaded');

                }

                //console.log(user);

                //console.log(DATA['PROGRESS']);
                //console.log(user.progress[DATA.ID]);
            }
        }

        if (user && user.email && !user.rewards) {

            user.rewards = DefaultData()['REWARDS'];
            setUser(user);

            console.log('set rewards');
        }

    }, [DATA, user.setprogress]);


    const getBio = (progress, project_id) => {

        /*
        progress[project_id]['bio'] = false;
        Object.values(progress[project_id]['packets']).forEach((packet, packet_index) => {
            Object.values(packet.activities).forEach((activity, activity_index) => {

                if (DATA['PACKETS'][packet_index]['activities'][activity_index] && DATA['PACKETS'][packet_index]['activities'][activity_index].progressRef == 'bio'){

                    progress[project_id].bio = {
                        'link': "/packet_"+(packet_index+1)+"/bio/"+(activity_index+1),
                        'packet_index': packet_index,
                        'activity_index': activity_index,
                    }
                }
            });
        });
        */

        return progress;
    }

    //const userId = Cookies.get().id;
    const updateUserProgress = async (progress) => {

        //         console.log('progress');
        //console.log(progress);
        //console.log(userId);

        if (userId) {
            const _ref_users = await doc(db, "users", userId);
            await updateDoc(_ref_users, {
                progress: progress,
            });
        }
    };


    //console.log('app');

    //console.log('all cookies');
    //console.log(Cookies.get());

    // user state could potentially only be local throughout components, needs to be global.

    //================================

    //*** get user ***

    useEffect(() => {

        //console.log(user);
        if (userId !== undefined && user.email === undefined) {

            const unsub = onSnapshot(doc(db, "users", userId), (doc) => {

                console.log("got user from cookie = " + userId);
                let user = doc.data();
                if (user) {
                    user.setprogress = false;
                    setUser(doc.data());
                }
            });
            return () => unsub();
        }

    }, [userId]);

    //===============================

    //*** get default data ***

    useEffect(() => {

        if (!projectId && !DATA['processed']) {

            console.log('default data processing');

            let Data = ProcessData(DATA);
            setDATA(Data);
            //console.log ('set default data');

            //console.log(DATA);

        }

    }, [projectId, user]);

    useEffect(() => {

        console.log(DATA);

    }, [DATA]);

    useEffect(() => {

        console.log(user);

    }, [user]);


    function loadUmamiScript() {
        const script = document.createElement('script');
        script.src = "https://umami.lagoon.live/script.js";
        script.async = true;
        script.setAttribute('data-website-id', '14d645eb-9847-4cc1-b5ac-165c49c24b9a');

        script.onload = () => {
            console.log('Umami script loaded');
            window.umamiLoaded = true;
        };

        document.head.appendChild(script);
    }

    useEffect(() => {
        loadUmamiScript();
    }, []);

    return (
        <>
            <div className="mobile-wrapper">
                {/* <div className="loader">
                    {Logo ?
                        <img className="lagoon-loader-logo" src={Logo} />
                    :
                        <img className="lagoon-loader-logo" />
                        // <LagoonLogoLoader className="lagoon-loader-logo" />
                    }
				</div> */}
                <div className="loader">
                    <div className="sp sp-wave"></div>
                </div>
                <Router basename="/">

                    <UserContext.Provider value={providerValue}>
                        <DataContext.Provider value={providerValueDATA}>
                            <Routes>

                                <Route path="/:code/"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardProject />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route path="/signup" element={<SignUp />} />
                                <Route path="/signup1" element={<SignUp1 />} />
                                <Route path="/t" element={<Twilio />} />
                                <Route path="/intro" element={<Intro />} />
                                <Route path="/qr" element={<Qr />} />
                                <Route path="/sendM" element={<SendMessage />} />



                                <Route
                                    path=":packet_id/ar/:activity_i"
                                    element={
                                        <ProtectedRoute>
                                            <PhotoBooth />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/mint/:activity_i"
                                    element={
                                        <ProtectedRoute>
                                            <Avatar />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/"
                                    element={
                                        <ProtectedRoute>
                                            <Dashboard />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/profile"
                                    element={
                                        <ProtectedRoute>
                                            <ProfilePage />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin"
                                    element={
                                        <ProtectedRoute>
                                            <Admin />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/activities"
                                    element={
                                        <ProtectedRoute>
                                            <Packets />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/rewards"
                                    element={
                                        <ProtectedRoute>
                                            <Rewards />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/video/:activity_i/"
                                    element={
                                        <ProtectedRoute>
                                            <Video />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/bio/:activity_i/"
                                    element={
                                        <ProtectedRoute>
                                            <Bio />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/download/:activity_i"
                                    element={
                                        <ProtectedRoute>
                                            <Downloader />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/quiz/:activity_i"
                                    element={
                                        <ProtectedRoute>
                                            <Quiz animate={true} />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/poll/:activity_i"
                                    element={
                                        <ProtectedRoute>
                                            <Quiz animate={true} />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/board"
                                    element={
                                        <ProtectedRoute>
                                            <Board />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/fulldashboard"
                                    element={
                                        <ProtectedRoute>
                                            <FullDashboard />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":packet_id/memory/:activity_i"
                                    element={
                                        <ProtectedRoute>
                                            <MemoryGame />
                                        </ProtectedRoute>
                                    }
                                />
                                {/* <Route
                                path="/match"
                                element={
                                    <ProtectedRoute>
                                        <FoundMatch />
                                    </ProtectedRoute>
                                }
                            /> */}
                            </Routes>
                        </DataContext.Provider>
                    </UserContext.Provider>

                </Router>
            </div>
        </>
    );
}

export default App;
