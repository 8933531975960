import Footer from "./Footer";
import {
	doc,
	collection,
	updateDoc,
	deleteDoc,
	deleteField,
	onSnapshot,
} from "firebase/firestore";
import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { db } from "../firebase";
import Activity from "./Activity";
import ArrowLeft from "./ArrowLeft";
import { UserContext } from "./User";
import Wallet from "./Wallet";

export default function Activities({props, i, unlock}) {

	//console.log('i='+i);

	const { user } = useContext(UserContext);
	const [activities, setActivities] = useState([]);
	const [showPacket1, setShowPacket1] = useState(true);
	const activityDescriptions = props.packet.activities;
	const activityProgress = props.progress.activities;
	const moduleProgress = props;
	const [points_earned, setPoints_earned] = useState(
		props.progress.package_points
	);

// 	console.log('=====-----');
// 	console.log(props);
// 	console.log(activityProgress);

	useEffect(() => {
		if (activityDescriptions) {
			// const reveal = [];
			// const progs = Object.entries(activityProgress);
			// progs.forEach((prog) => {
			// 	if (prog[0] !== "bio") {
			// 		reveal.push(prog[1].completed);
			// 	}
			// });
			// if (reveal.every((el) => el === true)) {
			// 	setShowBio(true);
			// }
			if (!moduleProgress.progress.packet_activated) {
				setShowPacket1(false);
			}
			// console.log(showPacket1);

			let temp = [];
			activityDescriptions.forEach((activity, i) => {
				if (activity && activity != false) {
					let thisProgress = activityProgress;
					temp.push(
						<Activity
							link={activity.link}
							name={activity.name}
							icon={activity.icon}
							data={activity.data}
							key={activity.id}
							id={activity.id}
							completed={activityProgress[i].completed}
							packet_activated={props.progress.packet_activated}
							// quiz_incorrect={!activityProgress.packet_1.quiz.correct}
							packet_id={activity.packet_id}
							points={activity.points}
							// show_bio={showBio}
						/>
					);
				}
				// if
			});
			setActivities(temp);
			setPoints_earned(props.progress.package_points);
		}
	}, [activityDescriptions, props, showPacket1]);

	let activeIndex = 0;

	if (user.progress && user.progress[user.project_id] && user.progress[user.project_id].activeBadge > 0 && user.progress[user.project_id].activeBadge <= user.progress[user.project_id].packets.length && user.progress[user.project_id].activeBadge != activeIndex){
		activeIndex = user.progress[user.project_id].activeBadge;
	}

	if(unlock)
		activeIndex = unlock;

	//console.log('activeIndex='+activeIndex);

	return (
		<div className="activities">
			<div
				className={`card-three ${
						i == activeIndex
						? ""
						: "disabled"
				}`}
			>
				<div className="dash"></div>
				<div className="top-three">
					{props ? props.packet.name : "..."} Activities
					{/* <NavLink to="/activities">
						<button className="business-card-btn view-all my-collectables">
							View all
						</button>
					</NavLink> */}
				</div>
				<div className="points-box">
					Points Earned: <span class="bold">{points_earned}</span>
				</div>
				{activities}
				<div className="wallet-activities">
					<Wallet />
				</div>
				<NavLink to="/profile">
					<button className="business-card-btn my-collectables">
						View my collectibles
					</button>
				</NavLink>
			</div>
			<Footer />
		</div>
	);
}
